import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment TeamCard on WpTeam {
      id
      title
      uri
      acf {
        profileImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 500
              )
            }
          }
        }
        bio
        linkedin
        instagram
        jobTitle
        email
        phone
      }
      info {
        id
        firstName
        lastName
        staffTypeId
        email
        position
        role
        thumbnail
      }
      phoneNumbers {
        number
        type
      }
    }
    fragment PropertyCard on WpProperty {
      databaseId
      id
      slug
      title
      uri

      inspections {
        start
        end
      }
      propertyMeta {
        id
        address {
          street
          suburb {
            name
            postcode
          }
        }
        auctionDetails {
          auctioneer
          dateTime
          venue
        }
        addressVisibility
        heading
        commercialListingType
        available
        carports
        garages
        openSpaces
        bed
        bath
        landAreaValue
        landAreaUnits
        floorAreaValue
        floorAreaUnits
        displayPrice
        displayAddress
        latitude
        longitude
        description
        class
        type
        status
        searchPrice
        isLeased
      }
      images {
        url
        thumb_1024
        thumb_180
      }
    }
    fragment ArticleCard on WpPost {
      id
      uri
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
              )
            }
          } 
        }
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wp {
        generalSettings {
          title
          url
        }
      }
    }
  `)
}

export default useLayoutQuery