import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo } from './icons'

class Header extends Component {

  state = {
    scrolled: false,
    offCanvas: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
	}

	componentWillUnmount() {
	  window.removeEventListener('scroll', this.scrollDetect)
	}

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > window.innerHeight,
    })
  }

	scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
	}

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled } = this.state
    let { intro } = this.props

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        <header className={`header${scrolled ? ' header--scrolled' : ''}${offCanvas ? ' header--active' : ''}${intro ? ' header--intro-active' : ''}`}>
          <div className='header__inner'>
            <nav className='header__nav'>
              { /*
              <ul>
                <li>
                  <Link to='/buy/' {...props}>Buy</Link>
                  <div className='header__nav-dropdown'>
                    <Link to='/upcoming-auctions/' {...props}>Upcoming Auctions</Link>
                    <Link to='/upcoming-inspections/' {...props}>Upcoming Inspections</Link>
                  </div>
                </li>
                <li><Link to='/sold/' {...props}>Sold</Link></li>
              </ul>
              */ }
            </nav>
            <Link to='/' title='Ruby Property Agents' className='header__logo' {...props}>
              <Logo color='#000000' />
            </Link>
            <nav className='header__nav header__nav--right'>
              <ul>
                <li><Link to='/about/'>About</Link></li>
                <li><Link to='/team/' {...props}>Team</Link></li>
                <li><Link to='/contact/'>Contact</Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className='header__hamburger'>
              { !offCanvas && <span className='lines'></span> }
              { offCanvas && <span className='close'>Close</span> }
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                { /*
                <li><Link to='/buy/' {...props}>Buy</Link></li>
                <li><Link to='/upcoming-auctions/' {...props}>Upcoming Auctions</Link></li>
                <li><Link to='/upcoming-inspections/' {...props}>Upcoming Inspections</Link></li>
                <li><Link to='/sold/' {...props}>Sold</Link></li>
            */ }
                <li><Link to='/about/' {...props}>About</Link></li>
                <li><Link to='/team/' {...props}>Team</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
