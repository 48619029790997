
import React from 'react'

export const Logo = ({ color = '#000000' }) => (
  <svg width="141" height="55" viewBox="0 0 141 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0928 10.4838L0.317139 20.2149V21.6252C0.334848 28.906 0.706749 53.3749 0.813007 53.463C0.883845 53.5335 9.79176 53.6217 20.5946 53.6393L40.2522 53.6922V52.6345V51.5944L21.604 51.4886C11.3679 51.4357 2.93816 51.3476 2.90274 51.3123C2.84961 51.277 2.74335 44.4899 2.6548 36.2572L2.47771 21.2727L11.3148 12.4582L20.1519 3.64375L29.2723 12.7226L38.3927 21.8015L39.1365 21.0435L39.898 20.3031L30.0692 10.519C24.6678 5.14221 20.1519 0.734982 20.0633 0.734982C19.9571 0.734982 15.4765 5.12458 10.0928 10.4838Z" fill="#CB2828" />
    <path d="M82.6313 26.1206C81.7281 26.385 80.9666 26.9844 80.5416 27.7425C80.0988 28.5005 80.0988 28.5181 80.0457 34.0536C79.9748 40.2766 80.0457 40.7526 81.0551 41.8103C81.7812 42.5684 82.6313 42.8681 84.048 42.8681C85.6596 42.8681 86.8462 42.1629 87.466 40.8407C87.7671 40.2061 87.8025 39.6067 87.8025 34.5825C87.8025 31.233 87.7316 28.765 87.6077 28.3595C87.1118 26.5437 84.7564 25.486 82.6313 26.1206ZM84.9689 28.6944C85.1992 28.906 85.2346 29.8051 85.2877 34.3181L85.3408 39.6949L84.8981 40.1356C84.048 40.9818 82.8084 40.5587 82.5782 39.307C82.525 38.9897 82.4896 36.5569 82.525 33.9126L82.5782 29.0823L83.0917 28.6415C83.6584 28.1656 84.4554 28.1832 84.9689 28.6944Z" fill="#CB2828" />
    <path d="M9.52612 34.4062V42.6917H10.7658C11.6336 42.6917 12.0055 42.6212 12.0232 42.4626C12.0232 42.3568 12.0232 41.2109 12.0055 39.924C11.9878 38.6371 12.0409 37.2268 12.094 36.7861L12.218 35.9928H12.9618C14.5025 35.9928 14.839 36.7155 14.839 39.9945C14.839 41.1757 14.8921 42.251 14.9453 42.4097C15.0338 42.6212 15.3172 42.6917 16.2381 42.6917H17.4246L17.3538 39.9064C17.2829 36.645 17.1235 35.9399 16.3443 35.1642L15.7599 34.5824L16.3443 34.0007C17.0704 33.2779 17.3183 32.4317 17.3183 30.6512C17.3183 28.8002 17.035 27.7953 16.3443 27.0902C15.5651 26.3321 14.6088 26.1206 11.8461 26.1206H9.52612V34.4062ZM14.3608 28.9236C14.6973 29.2409 14.7327 29.4877 14.7327 30.9156C14.7504 31.9029 14.6619 32.7138 14.5202 32.943C14.2723 33.3837 13.068 33.7891 12.4482 33.6305C12.0763 33.5423 12.0586 33.4366 12.0232 31.2858C11.97 28.3947 11.9878 28.3418 13.1035 28.4476C13.6879 28.5181 14.1129 28.6768 14.3608 28.9236Z" fill="#CB2828" />
    <path d="M20.4529 26.2264C20.258 26.4203 20.3112 38.5842 20.506 39.7654C20.8425 41.828 22.0467 42.8504 24.1187 42.8681C24.6854 42.8681 25.4647 42.7799 25.8543 42.6565C26.7043 42.4097 27.6784 41.3696 27.9263 40.4529C28.0503 40.0298 28.1211 37.2268 28.1211 32.943V26.1206H26.97H25.8189V32.4141C25.8189 38.8487 25.7126 39.9593 25.1813 40.3471C24.7209 40.6821 23.9771 40.6292 23.4989 40.2414L23.0739 39.9064L22.9853 33.0488L22.8968 26.2088L21.7457 26.1559C21.1081 26.1206 20.5237 26.1559 20.4529 26.2264Z" fill="#CB2828" />
    <path d="M31.486 34.4062V42.6917H34.0362C38.5876 42.6917 39.473 41.9513 39.473 38.1259C39.4908 35.9046 39.172 34.8998 38.2511 34.2651L37.7198 33.8773L38.3042 33.2955C38.9949 32.608 39.2782 31.7266 39.2782 30.1929C39.2782 28.712 38.9772 27.7777 38.2865 27.0902C37.525 26.3321 36.5687 26.1206 33.806 26.1206H31.486V34.4062ZM36.2499 28.8354C36.9583 29.4701 36.8697 32.2378 36.1259 32.608C35.9665 32.6962 35.5238 32.8196 35.1696 32.8724C34.1602 33.0311 34.0185 32.7667 33.9831 30.757C33.9477 28.43 33.9831 28.3594 35.0634 28.4476C35.5238 28.5005 36.0728 28.6768 36.2499 28.8354ZM36.4624 35.6226C36.852 35.9222 36.8874 36.1162 36.9406 37.738C37.0114 39.448 36.9937 39.5185 36.551 39.9593C36.1436 40.3647 35.9665 40.4176 35.0634 40.3647L34.0539 40.3118L34.0185 37.9319C34.0008 36.6274 34.0362 35.4991 34.0716 35.4286C34.1247 35.3405 34.5852 35.2876 35.0988 35.2876C35.7717 35.2876 36.1791 35.3934 36.4624 35.6226Z" fill="#CB2828" />
    <path d="M42.0056 26.6671C42.0587 26.9844 42.7848 29.4877 43.6171 32.2554L45.1224 37.2797V39.9769V42.6917H46.3621H47.6018V40.0827V37.4912L49.1956 32.1144C50.0811 29.1527 50.8426 26.5966 50.8958 26.4203C50.9843 26.1558 50.8603 26.1206 49.8155 26.1206C48.4164 26.1206 48.6112 25.7856 47.4955 30.0871C47.0351 31.8323 46.6455 33.2955 46.61 33.3308C46.4507 33.5071 46.2913 33.0311 45.4235 29.7169L44.5026 26.2087L43.1921 26.1558L41.8816 26.1029L42.0056 26.6671Z" fill="#CB2828" />
    <path d="M58.5818 34.3885V42.6917H59.8215C60.6892 42.6917 61.0611 42.6212 61.0788 42.4626C61.0788 42.3568 61.0965 40.9817 61.1143 39.4304L61.1497 36.6098L62.3893 36.4687C64.0009 36.2925 64.6385 36.0809 65.2229 35.4992C66.0906 34.6353 66.25 34.0007 66.2677 31.3211C66.2855 29.0822 66.25 28.7825 65.8604 28.0069C65.5771 27.4075 65.2229 27.0373 64.6385 26.6847C63.8415 26.2263 63.6644 26.2087 61.2028 26.1558L58.5818 26.0853V34.3885ZM63.3988 28.9412C63.6644 29.2585 63.7176 29.664 63.7176 31.3035C63.7176 33.842 63.5759 34.0888 62.1591 34.0183L61.1497 33.9654L61.0965 31.6208C61.0611 30.3339 61.0788 29.0646 61.1497 28.8178C61.2559 28.3947 61.3091 28.3771 62.1591 28.4476C62.8321 28.5181 63.1686 28.6591 63.3988 28.9412Z" fill="#CB2828" />
    <path d="M69.2074 34.4062V42.6918H70.5002H71.7753V39.3775V36.0809L72.3774 36.0281C74.1307 35.8518 74.414 36.3277 74.5203 39.8712L74.6088 42.6036L75.8485 42.6565L77.1059 42.7094L77.035 39.924C76.9642 36.6451 76.8048 35.9399 76.0256 35.1642L75.4412 34.5825L76.0256 34.0007C76.7517 33.2779 76.9996 32.4318 76.9996 30.6512C76.9996 28.8002 76.7163 27.7954 76.0256 27.0902C75.2464 26.3322 74.29 26.1206 71.5274 26.1206H69.2074V34.4062ZM73.9713 28.8355C74.6442 29.4348 74.6619 32.5023 73.989 33.1722C73.5817 33.5776 72.2889 33.8068 71.9701 33.5424C71.7044 33.3132 71.5628 29.7345 71.793 28.8531C71.8993 28.3947 71.9524 28.3771 72.767 28.4476C73.2452 28.5005 73.7942 28.6768 73.9713 28.8355Z" fill="#CB2828" />
    <path d="M91.1673 34.3885V42.6917H92.407C93.2747 42.6917 93.6466 42.6212 93.6643 42.4626C93.6643 42.3568 93.6821 40.9817 93.6998 39.4304L93.7352 36.6098L95.0634 36.4687C96.9229 36.2748 97.8438 35.7812 98.4105 34.6706C98.8355 33.8597 98.8533 33.6129 98.8533 31.3211C98.8533 29.0999 98.8001 28.7649 98.4459 28.0069C98.1449 27.4075 97.8084 27.0373 97.2063 26.6847C96.4448 26.2263 96.2499 26.2087 93.7883 26.1558L91.1673 26.0853V34.3885ZM95.9843 28.9412C96.2499 29.2585 96.3031 29.664 96.3031 31.3035C96.3031 33.842 96.1614 34.0888 94.7446 34.0183L93.7352 33.9654L93.6821 31.6032C93.6466 30.3163 93.6643 29.047 93.7352 28.8002C93.8414 28.3947 93.8946 28.3771 94.7446 28.4476C95.4176 28.5181 95.7541 28.6591 95.9843 28.9412Z" fill="#CB2828" />
    <path d="M101.616 34.4062V42.6917H105.158H108.7V41.5459V40.4H106.486C103.865 40.4 104.06 40.6292 104.131 37.5794L104.184 35.552L106.008 35.4991L107.814 35.4463V34.318V33.1898L106.008 33.1369L104.184 33.084L104.131 31.1448C104.06 28.2008 103.865 28.4123 106.486 28.4123H108.7V27.2665V26.1206H105.158H101.616V34.4062Z" fill="#CB2828" />
    <path d="M111.888 34.4062V42.6918H113.127H114.367V42.11C114.385 41.8103 114.385 40.4353 114.367 39.0602C114.367 37.7028 114.402 36.4512 114.473 36.2925C114.544 36.0809 114.792 35.9928 115.288 35.9928C116.864 35.9928 117.076 36.4159 117.2 39.8712L117.289 42.6036L118.653 42.6565L120.016 42.7094L119.839 42.1277C119.751 41.8103 119.68 40.5587 119.68 39.3423C119.68 36.7332 119.485 35.9399 118.706 35.1642L118.121 34.5825L118.706 34.0007C119.432 33.2779 119.68 32.4318 119.68 30.6512C119.68 28.8002 119.396 27.7954 118.706 27.0902C117.926 26.3322 116.97 26.1206 114.207 26.1206H111.888V34.4062ZM116.651 28.8355C117.271 29.3996 117.36 32.2907 116.775 33.084C116.545 33.419 115.181 33.7539 114.721 33.6129C114.438 33.5248 114.402 33.2603 114.385 31.2506C114.367 30.0166 114.402 28.8531 114.473 28.6944C114.579 28.43 114.739 28.3947 115.465 28.4476C115.925 28.5005 116.474 28.6768 116.651 28.8355Z" fill="#CB2828" />
    <path d="M122.159 27.2665V28.4123H123.487H124.815V35.552V42.6917H126.144H127.472V35.552V28.4123H128.8H130.128V27.2665V26.1206H126.144H122.159V27.2665Z" fill="#CB2828" />
    <path d="M132.076 26.3497C132.076 26.4908 132.802 29.0117 133.67 31.9557L135.264 37.2973V39.9945V42.6917H136.504H137.743V40.0827V37.4912L139.408 31.9381C140.311 28.8883 141.02 26.3321 140.984 26.244C140.949 26.1735 140.435 26.1206 139.851 26.1206C138.576 26.1206 138.753 25.8209 137.637 30.0871C137.177 31.8323 136.787 33.2955 136.752 33.3308C136.592 33.5071 136.433 33.0311 135.565 29.7169L134.644 26.2087L133.369 26.1558C132.342 26.1206 132.076 26.1558 132.076 26.3497Z" fill="#CB2828" />
    <path d="M53.3042 49.3379C53.1094 49.5142 52.5604 50.5719 52.1176 51.6825C51.6572 52.7931 51.179 53.798 51.0374 53.9038C50.5592 54.274 50.7717 54.5031 51.5864 54.5031C52.401 54.5031 52.5427 54.3797 52.1885 54.0272C52.0645 53.9038 52.0291 53.6217 52.0999 53.3749C52.2062 52.9518 52.2947 52.9165 53.1625 52.9165C54.0657 52.9165 54.1365 52.9518 54.2959 53.4278C54.4376 53.8332 54.4199 53.9743 54.2251 54.0448C53.5875 54.2916 54.0657 54.5031 55.2168 54.5031C56.4919 54.5031 56.7044 54.3797 56.12 53.9566C55.9429 53.8156 55.3762 52.7226 54.8803 51.5062C54.3668 50.2898 53.8886 49.2321 53.8178 49.1616C53.7469 49.0911 53.5167 49.1616 53.3042 49.3379ZM53.5167 51.5062C53.6761 51.8941 53.8 52.2643 53.8 52.2995C53.8 52.3524 53.5167 52.3877 53.1802 52.3877C52.8437 52.3877 52.5604 52.3348 52.5604 52.2819C52.5604 51.9646 52.9854 50.8011 53.0917 50.8011C53.1802 50.8011 53.3573 51.1184 53.5167 51.5062Z" fill="#CB2828" />
    <path d="M60.8486 49.1968C58.4401 49.9725 58.015 52.9694 60.1225 54.2034C60.7954 54.5913 62.3893 54.6618 63.1862 54.3268C63.6644 54.1329 63.7175 54.0271 63.7175 53.3396C63.7175 52.9341 63.8061 52.5287 63.8946 52.4758C64.3374 52.1937 63.9832 52.0351 62.9206 52.0351C61.7518 52.0351 61.4861 52.2114 62.1237 52.5639C62.3716 52.705 62.4779 52.9341 62.4779 53.3572C62.4779 53.9214 62.4247 53.9742 61.9643 53.9742C59.8745 53.9742 59.5381 50.0959 61.6101 49.6552C62.1945 49.5141 62.7612 49.7962 62.9206 50.2722C62.9737 50.4661 63.1508 50.6248 63.2925 50.6248C63.5936 50.6248 63.6467 49.4789 63.3633 49.3026C63.0446 49.1087 61.3444 49.0382 60.8486 49.1968Z" fill="#CB2828" />
    <path d="M68.2865 49.1616C66.8343 49.2321 66.3739 49.3908 66.9937 49.6376C67.2062 49.7081 67.2594 50.1312 67.2594 51.8412C67.2594 53.5336 67.2062 53.9566 66.9937 54.0448C66.852 54.0977 66.7281 54.2211 66.7281 54.3269C66.7281 54.4326 67.5427 54.5031 68.9241 54.5031C70.9784 54.5031 71.1378 54.4855 71.2263 54.1506C71.2794 53.9566 71.3857 53.6393 71.4388 53.4454C71.5805 52.9342 71.0846 52.9871 70.7304 53.5336C70.4825 53.9214 70.3054 53.9743 69.4731 53.9743H68.499V53.0047V52.0351H69.1189C69.5439 52.0351 69.7741 52.1409 69.9158 52.3877C70.1991 52.9342 70.4471 52.6345 70.4471 51.7707C70.4471 50.8892 70.1991 50.6072 69.9158 51.1537C69.7564 51.4357 69.5793 51.5062 69.1543 51.471C68.6053 51.4181 68.5876 51.4005 68.5876 50.5366V49.6552L69.296 49.6023C69.8804 49.5494 70.0575 49.6199 70.3762 50.0254C70.8544 50.6072 71.1555 50.4661 70.9961 49.7081C70.8544 49.0558 70.9075 49.0558 68.2865 49.1616Z" fill="#CB2828" />
    <path d="M82.3834 49.1968C82.2417 49.3555 82.3125 50.6072 82.4896 50.7129C82.5782 50.7658 82.773 50.5366 82.9147 50.1841C83.1449 49.6376 83.2689 49.5494 83.6762 49.6023L84.172 49.6552L84.2252 51.7883C84.2606 53.8156 84.2429 53.939 83.9064 54.0272C83.7116 54.08 83.5522 54.2035 83.5522 54.3092C83.5522 54.4326 84.0658 54.5031 84.9867 54.5031C86.0493 54.5031 86.4035 54.4503 86.3503 54.274C86.2972 54.1682 86.1024 54.0448 85.8722 54.0095C85.5003 53.9566 85.4826 53.9038 85.5357 51.8059L85.5888 49.6552L86.0847 49.6023C86.5097 49.5494 86.616 49.6376 86.7931 50.0783C87.0941 50.9068 87.4837 50.7834 87.4129 49.8844L87.3598 49.144L84.9159 49.1263C83.5699 49.1263 82.4188 49.1616 82.3834 49.1968Z" fill="#CB2828" />
    <path d="M91.2557 49.285C90.6359 49.5494 90.3702 49.8491 90.2285 50.4485C90.0515 51.136 90.4588 51.6649 91.6099 52.2643C92.5485 52.7579 92.6725 52.8989 92.6725 53.3573C92.6725 53.8509 92.6193 53.8861 91.911 53.939C91.2026 53.9919 91.1317 53.9566 90.9369 53.463C90.6004 52.5463 89.9275 52.8989 90.1754 53.8685C90.264 54.2034 90.4588 54.3268 91.1494 54.4855C92.5485 54.7852 93.8767 54.2387 94.0892 53.2691C94.2663 52.4934 93.8236 51.9469 92.6193 51.3652C91.7339 50.9421 91.6099 50.8187 91.6099 50.378C91.6099 50.0959 91.7339 49.7962 91.8932 49.6904C92.336 49.4084 93.0621 49.6904 93.186 50.184C93.2569 50.4309 93.4163 50.6248 93.5579 50.6248C93.983 50.6248 93.9121 49.4436 93.4871 49.2145C93.009 48.9677 91.8932 49.0029 91.2557 49.285Z" fill="#CB2828" />
    <path d="M74.166 49.3731C74.166 49.4613 74.29 49.5671 74.4317 49.6376C74.6442 49.7081 74.6973 50.1312 74.6973 51.8412C74.6973 53.5336 74.6442 53.9566 74.4317 54.0448C73.8472 54.2563 74.2192 54.5031 75.14 54.5031C76.1141 54.5031 76.486 54.2211 75.7599 54.0272C75.4411 53.939 75.4057 53.7804 75.4057 52.4582C75.4057 51.6473 75.4411 50.9774 75.4765 50.9774C75.5119 50.9774 76.238 51.7707 77.0881 52.7403C77.9205 53.7098 78.7174 54.5031 78.8413 54.5031C79.0362 54.5031 79.107 53.9566 79.1601 52.1938C79.2133 50.2722 79.2841 49.8491 79.532 49.6552C80.0279 49.3026 79.8862 49.2145 78.8591 49.2145C77.9027 49.2145 77.5486 49.4436 78.2392 49.6199C78.558 49.7081 78.5934 49.8667 78.5934 50.9597C78.5934 51.6473 78.5403 52.2114 78.4517 52.2114C78.3809 52.2114 77.7256 51.5415 76.9996 50.7129C75.7245 49.3026 75.6359 49.2321 74.9275 49.2145C74.5025 49.2145 74.166 49.285 74.166 49.3731Z" fill="#CB2828" />
  </svg>

)

export const Instagram = ({ color = '#000000' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.58763 0.75H12.6741C15.3353 0.75 17.5114 2.89923 17.5114 5.52483V12.5921C17.5114 15.2183 15.3353 17.3669 12.6741 17.3669H5.58763C2.92647 17.3669 0.75 15.2183 0.75 12.5921V5.52483C0.75 2.89923 2.92644 0.75 5.58763 0.75ZM9.08521 4.5721C11.6712 4.5721 13.7697 6.6706 13.7697 9.2566C13.7697 11.8429 11.6712 13.9411 9.08521 13.9411C6.49858 13.9411 4.40039 11.8429 4.40039 9.2566C4.40039 6.6706 6.49858 4.5721 9.08521 4.5721ZM9.08501 6.15432C10.7975 6.15432 12.1873 7.54384 12.1873 9.2566C12.1873 10.9694 10.7975 12.3592 9.08501 12.3592C7.37194 12.3592 5.98242 10.9694 5.98242 9.2566C5.98242 7.54384 7.37194 6.15432 9.08501 6.15432ZM13.6178 3.92285C14.0378 3.92285 14.3784 4.26342 14.3784 4.68319C14.3784 5.10327 14.0378 5.44385 13.6178 5.44385C13.198 5.44385 12.8574 5.10327 12.8574 4.68319C12.8574 4.26342 13.198 3.92285 13.6178 3.92285ZM6.16642 2.10742H12.0954C14.3216 2.10742 16.1424 3.91917 16.1424 6.13309V12.0917C16.1424 14.3056 14.3217 16.1171 12.0954 16.1171H6.16642C3.94016 16.1171 2.11914 14.3056 2.11914 12.0917V6.13304C2.11914 3.91917 3.94016 2.10742 6.16642 2.10742Z" fill={color} />
  </svg>
)

export const Facebook = ({ color = '#000000' }) => (
  <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.49751 17.25H1.99846V8.99896H0.25V6.15593H1.99846V4.44907C1.99846 2.12986 2.9852 0.75 5.79043 0.75H8.12527V3.59407H6.66599C5.57394 3.59407 5.50178 3.99137 5.50178 4.73285L5.49697 6.15593H8.1413L7.83181 8.99896H5.49697V17.25H5.49751Z" fill={color} />
  </svg>
)

export const LinkedIn = ({ color = '#000000' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.539062 5.26367H3.60191V15.9381H0.539062V5.26367Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.99254 3.9283H1.97037C0.861644 3.9283 0.142822 3.1123 0.142822 2.07897C0.142822 1.02451 0.882894 0.224609 2.01379 0.224609C3.14376 0.224609 3.83856 1.0225 3.86074 2.07595C3.86074 3.10928 3.14376 3.9283 1.99254 3.9283Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.8572 15.9387H12.3841V10.4138C12.3841 8.96797 11.8409 7.98193 10.6462 7.98193C9.73243 7.98193 9.22427 8.64701 8.98774 9.28995C8.89904 9.51935 8.9129 9.84032 8.9129 10.1623V15.9387H5.47217C5.47217 15.9387 5.51652 6.15272 5.47217 5.26327H8.9129V6.93854C9.11617 6.20605 10.2156 5.16064 11.9702 5.16064C14.147 5.16064 15.8572 6.69706 15.8572 10.0023V15.9387Z" fill={color} />
  </svg>
)

export const Search = ({ color = '#000000' }) => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.491 8.84128C10.1902 7.96429 10.5736 6.85386 10.5736 5.73008C10.5736 2.96155 8.31204 0.700012 5.52952 0.700012C2.74756 0.700012 0.5 2.96155 0.5 5.73008C0.5 8.49862 2.76154 10.7602 5.53007 10.7602C6.6405 10.7602 7.75035 10.3763 8.64126 9.67758L12.1637 13.2L13.0001 12.3776L9.491 8.84128ZM5.53015 9.56782C3.43295 9.56782 1.71967 7.85453 1.71967 5.75733C1.71967 3.66013 3.43295 1.94685 5.53015 1.94685C7.62735 1.94685 9.34064 3.66013 9.34064 5.75733C9.34064 7.85453 7.62735 9.56782 5.53015 9.56782Z" fill={color} />
  </svg>
)

export const Icon = ({ color = '#FFFFFF' }) => (
  <svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-0.00206184 5.91144H4.86914V32.4773H-0.00206184V5.91144Z" fill={color} />
    <path d="M27.13 5.91144H32V32.4773H27.13V5.91144Z" fill={color} />
    <path d="M13.5655 0H18.4355V15.974H13.5655V0Z" fill={color} />
    <path d="M13.5655 21.0272H18.4355V37H13.5655V21.0272Z" fill={color} />
  </svg>
)

export const Tick = ({ color = '#666868' }) => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.62853 15.4445C7.30884 15.4445 6.99816 15.3401 6.74294 15.1467L0.48053 10.3964L2.25299 8.05801L7.27394 11.8672L15.0471 0.207642L17.4873 1.83444L8.8492 14.7909C8.57721 15.1995 8.1196 15.4445 7.62846 15.4445L7.62853 15.4445Z" fill={color} />
  </svg>
)

export const Play = () => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43 31L23 47L23 15L43 31Z" fill="white" />
    <circle cx="31" cy="31" r="30" stroke="white" stroke-width="2" />
  </svg>
)
