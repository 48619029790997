import React, { Component } from 'react'
import Link from '../utils/link'
import { LinkedIn, Facebook, Instagram } from './icons'
import AppraisalForm from './appraisal-form'

class Footer extends Component {

  state = {
    appraisal: false
  }

  render() {

    let { appraisal } = this.state

    return (
      <>
        <footer className='footer'>
          <div className='footer__inner'>
            <div className='footer__row footer__top'>
              <div className='footer__col'>
                <ul>
                  <li><Link to='https://www.instagram.com/rubypropertyagents/'><Instagram /></Link></li>
                  <li><Link to='https://www.linkedin.com/in/joseph-boghos-0752812b4/'><LinkedIn /></Link></li>
                  <li><Link to='https://www.facebook.com/profile.php?id=61556010556173'><Facebook /></Link></li>
                </ul>
              </div>
              <div className='footer__col'>
                <ul>
                  { /*
                  <li><Link to='/buy/'>Buy</Link></li>
                  <li><Link to='/upcoming-auctions/'>Upcoming Auctions</Link></li>
                  <li><Link to='/upcoming-inspections/'>Upcoming Inspections</Link></li>
                  <li><Link to='/sold/'>Sold</Link></li>
    */ }
                  <li>
                    <button className='appraisal-button' onClick={() => this.setState({ appraisal: !appraisal })}>Appraisal</button>
                  </li>
                  <li><Link to='/about/'>About</Link></li>
                  <li><Link to='/team/'>Team</Link></li>
                  <li><Link to='/contact/'>Contact</Link></li>
                </ul>
              </div>
            </div>
            <div className='footer__row footer__bottom'>
              <div className='footer__col'>
                <p>© Ruby Property Agents { new Date().getFullYear() }. All rights reserved.</p>
              </div>
              <div className='footer__col'>
                <ul>
                  <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
                  { /*
                  <li><Link to='https://www.google.com/maps/place/6%2F400+Canterbury+Rd,+Surrey+Hills+VIC+3127/@-37.8263036,145.0956702,17z/data=!3m2!4b1!5s0x6ad640e3eff0d8d9:0x7a80aec898f03655!4m6!3m5!1s0x6ad640e3efe6c66b:0xcf3db6b8fa0e3e7f!8m2!3d-37.8263036!4d145.0982505!16s%2Fg%2F11tbz599n7?entry=ttu'>6/400 Canterbury Road. Surrey Hills VIC 3127</Link></li>
                  <li><Link to='tel:0394703390'>03 9470 3390</Link></li>
                  */ }
                </ul>
              </div>
              <div className='footer__col'>
                <ul>
                  <li>Site by <Link to='https://codedrips.com'>CodeDrips</Link></li>
                </ul>
              </div>
            </div>
            <div className='footer__row footer__mobile'>
              <div className='footer__col'>
                <ul>
                  <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
                </ul>
              </div>
              <div className='footer__col'>
                <p>© Ruby Property Agents { new Date().getFullYear() }. All rights reserved.</p>
                <Link to='https://codedrips.com'>Site by CodeDrips</Link>
              </div>
            </div>
          </div>
        </footer>
        <div className={`appraisal ${appraisal ? ' active' : ''}`}>
          <div className='appraisal__overlay'>
            <div className='appraisal__form'>
              <button type='button' onClick={() => this.setState({ appraisal: false })} className='appraisal__close'>Close</button>
              <div className='appraisal__form-wrapper'>
                <AppraisalForm id={'header'} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Footer
